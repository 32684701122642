import { KeyboardEvent, UIEvent } from 'react'

const stopPropagation = (event: UIEvent) => {
  event.stopPropagation()
}

const incrementingKeyHandler = (
  event: KeyboardEvent,
  updateControlValue: any,
  escapeAction?: any
) => {
  const bumpElementValue = (bumpBy: number) => {
    // @ts-ignore // for some reason KeyboardEvent does have .target.value ??
    const currValue = parseFloat(event.target.value)

    if (!isNaN(currValue)) {
      const newValue = currValue + bumpBy
      // @ts-ignore
      event.target.value = String(newValue)
      updateControlValue(newValue)
    }
  }

  let okToPropagate = false

  // TODO: prevent non-numeric for some but not all inputs?
  // if (event.which < 48 || event.which > 57)
  // {
  //     event.preventDefault();
  // }

  switch (event.keyCode) {
    case 27: // Esc key
      if (escapeAction) {
        escapeAction()
      }
      break
    // case 39: // Right arrow
    // case 37: // Left arrow
    case 32: // SPACE key
      okToPropagate = true // playback controls TODO: store in keysToAllow array or something
      event.preventDefault()
      break

    case 13: // RETURN key
      bumpElementValue(0)
      break

    case 38: // Up arrow
      bumpElementValue(1)
      break

    case 40: // Down arrow
      bumpElementValue(-1)
      break

    default:
  }

  if (!okToPropagate) {
    event.stopPropagation()
  }
}

export { incrementingKeyHandler, stopPropagation }

import React, { ChangeEvent, useContext, useState } from 'react'

import PrintContext, { PrintContextType } from './PrintContext'
import { formattedTimeStamp } from './util'
import UserContext, { UserContextType } from './UserContext'

const IMAGE_BOUNDS = { min: 32, max: 4000 }

type Props = {
  formula: string
}
const MintSettings = ({ formula }: Props) => {
  const {
    userContext: { isAutoResize },
  } = useContext(UserContext) as UserContextType
  const {
    printContext: {
      timestamp,
      title,
      imageSize: { width: imageWidth },
      pixelRatio,
      crawlerTextColor,
      isShowFormula,
      isShowTitle,
      isShowTimestamp,
      isShowSignature,
    },
    setImageSize,
    setPixelRatio,
    setIsShowFormula,
    setIsShowTitle,
    setIsShowTimestamp,
    setIsShowSignature,
    setTitle,
    setCrawlerTextColor,
  } = useContext(PrintContext) as PrintContextType
  const [isEditingSize, setIsEditingSize] = useState(false)
  const [size, setSize] = useState(0)
  const workingSize = !isAutoResize && isEditingSize ? size : imageWidth

  const onShowFormulaToggled = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowFormula(event.target.checked)
  }
  const onShowTitleToggled = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowTitle(event.target.checked)
  }
  const onShowTimestampToggled = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowTimestamp(event.target.checked)
  }
  const onShowSignatureToggled = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowSignature(event.target.checked)
  }

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
    window.history.replaceState({}, '', '/') // forking, clear url 'til next upload
  }

  const onSizeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(event.target.value)
    setIsEditingSize(true)
    setSize(newSize)
  }
  const finalizeSize = (event: ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(event.target.value)
    setIsEditingSize(false)
    if (newSize >= IMAGE_BOUNDS.min && newSize <= IMAGE_BOUNDS.max) {
      setImageSize(newSize)
    } else {
      setSize(imageWidth)
    }
  }
  const onPixelRatioChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const newPixelRatio = Math.min(parseInt(event.target.value), 6)
    setPixelRatio(newPixelRatio)
  }

  const onCrawlerTextColorChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setCrawlerTextColor(event.target.value)
  }

  const timestampDisplay = formattedTimeStamp(timestamp, true)

  return (
    <div className="mint-settings">
      <div className="setting">
        <label>Timestamp:</label>
        <input type="text" value={timestampDisplay} readOnly />
      </div>
      <div className="setting">
        <label>Title:</label>
        <input type="text" value={title} onChange={onTitleChanged} />
      </div>
      <div className="setting">
        <label>Crawler:</label>
        <input type="color" value={crawlerTextColor} onChange={onCrawlerTextColorChanged} />
      </div>
      <div className="setting">
        <label>Image Size (px):</label>
        <input
          className="number"
          type="text"
          value={workingSize}
          disabled={isAutoResize}
          onChange={onSizeChanged}
          onBlur={finalizeSize}
        />
      </div>
      <div className="setting">
        <label>{`Scale up by:`}</label>
        <select defaultValue={pixelRatio} onChange={onPixelRatioChanged}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
      </div>
      <div className="setting">
        <label>Show Formula:</label>
        <input type="checkbox" checked={isShowFormula} onChange={onShowFormulaToggled} />
      </div>
      <div className="formula">{formula}</div>
      <div className="setting">
        <label>Show Title:</label>
        <input type="checkbox" checked={isShowTitle} onChange={onShowTitleToggled} />
      </div>
      <div className="setting">
        <label>Show Timestamp:</label>
        <input type="checkbox" checked={isShowTimestamp} onChange={onShowTimestampToggled} />
      </div>
      <div className="setting">
        <label>Show Signature:</label>
        <input type="checkbox" checked={isShowSignature} onChange={onShowSignatureToggled} />
      </div>
    </div>
  )
}

export default MintSettings

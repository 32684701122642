import React, { useContext } from 'react'

import { Config, NormalizedControlMap } from './types'
import {
  calculateFrameDelta,
  ControlMap,
  getFrameCount,
  getInitialConfigFromControls,
  getNormalizedAnimatingControls,
} from './GraphParamDefs'
import PrintContext, { PrintContextType } from './PrintContext'

export interface IGraphContext {
  graphRef: any
  isLog: boolean
  graphConfig: Config
  graphControls: ControlMap
  animationControls: NormalizedControlMap
  frameCount: number
  frameNumber: number
}

export type GraphContextType = {
  graphContext: IGraphContext
  setGraphRef: (graphRef: any) => void
  setIsLog: (isLog: boolean) => void
  setFrame: (frameNum: number) => void
  setFirstFrameNumber: (frameNum: number) => void
  updateControlValue: (key: string, value: any) => void
  updateControlParam: (key: string, param: string, value: any) => void
}

type GraphContextProviderProps = {
  children?: React.ReactNode
  initialControls: ControlMap
  firstFrameOffset: number
  firstDisplayFrameNumber: number
}

const GraphContext = React.createContext<GraphContextType | null>(null)

export const GraphContextProvider = ({
  children,
  initialControls,
  firstFrameOffset,
  firstDisplayFrameNumber,
}: GraphContextProviderProps) => {
  const initialConfig = getInitialConfigFromControls(initialControls)
  const animationControls = getNormalizedAnimatingControls(
    initialControls,
    initialConfig,
    firstFrameOffset
  )
  const { setTimestamp } = useContext(PrintContext) as PrintContextType
  const firstFrameDelta = calculateFrameDelta(firstDisplayFrameNumber, animationControls)
  const firstFrameConfig = { ...initialConfig, ...firstFrameDelta }
  const frameCount = getFrameCount(animationControls)
  // NOTE: only call setGraphContext with (prevContext: IGraphContext) => IGraphContext
  // because you may need to update state while animating.
  const [graphContext, setGraphContext] = React.useState<IGraphContext>({
    graphRef: React.createRef(),
    isLog: false,
    graphConfig: firstFrameConfig,
    graphControls: initialControls,
    animationControls,
    frameCount,
    frameNumber: firstDisplayFrameNumber,
  })
  const setGraphRef = (graphRef: any) => {
    setGraphContext((prevContext) => ({ ...prevContext, graphRef }))
  }
  const setIsLog = (isLog: boolean) => {
    setGraphContext((prevContext) => ({ ...prevContext, isLog }))
  }
  const setFirstFrameNumber = (frameNumber: number) => {
    setGraphContext((prevContext) => ({ ...prevContext, frameNumber }))
  }
  const updateControlValue = (key: string, value: any) => {
    setGraphContext((prevContext) => {
      const { graphConfig: prevConfig } = prevContext
      const graphConfig = { ...prevConfig, [key]: value }
      setTimestamp(Date.now())
      return { ...prevContext, graphConfig }
    })
  }
  const updateControlParam = (key: string, param: string, value: any) => {
    setGraphContext((prevContext) => {
      const { graphControls: prevControls, graphConfig } = graphContext
      const newParam = { ...prevControls[key], [param]: value }
      const graphControls = { ...prevControls, [key]: newParam }
      const animationControls = getNormalizedAnimatingControls(graphControls, graphConfig)
      const frameCount = getFrameCount(animationControls)
      return { ...prevContext, graphControls, animationControls, frameCount }
    })
  }
  const setFrame = (frameNumber: number) => {
    setGraphContext((prevContext) => {
      const { graphConfig: prevConfig, animationControls } = prevContext
      const frameChanges = calculateFrameDelta(frameNumber, animationControls)
      const newConfig = { ...prevConfig, ...frameChanges }
      return { ...prevContext, graphConfig: newConfig, frameNumber }
    })
  }

  return (
    <GraphContext.Provider
      value={{
        graphContext,
        setGraphRef,
        setIsLog,
        updateControlValue,
        updateControlParam,
        setFrame,
        setFirstFrameNumber,
      }}
    >
      {children}
    </GraphContext.Provider>
  )
}

export default GraphContext

import { faCopy, faMaximize } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import S3 from 'aws-sdk/clients/s3'
import React, { useState, useContext, ChangeEvent } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Download from 'downloadjs'
import ReactGA from 'react-ga'
import screenfull from 'screenfull'

import { attachConfigToUrl } from './GraphParamDefs'
import GraphContext, { GraphContextType } from './GraphContext'
import PrintContext, { getManifest, PrintContextType } from './PrintContext'
import UserContext, { UserContextType } from './UserContext'

// @ts-ignore
const { S3_BUCKET_NAME, S3_ACCESS_ID, S3_ACCESS_SECRET } = MULTIPOLAR_ENV

type Props = { isShowAdvanced: boolean; onLoadFile: (file: File) => void }

const READY_TO_UPLOAD = 'upload'
const TOAST_MILLIS = 4000

const WheelActions = ({ isShowAdvanced, onLoadFile }: Props) => {
  const [uploadStatus, setUploadStatus] = useState(READY_TO_UPLOAD)
  const [isCopied, setIsCopied] = useState(false)
  const [downloadsClicked, setDownloadsClicked] = useState(0)
  const [linkCopyCount, setLinkCopyCount] = useState(0)
  const {
    userContext: { isAutoResize, isFullscreen },
    setIsAutoResize,
  } = useContext(UserContext) as UserContextType
  const {
    graphContext: { graphRef: stageRef, graphConfig: config, isLog, graphControls },
    setIsLog,
  } = useContext(GraphContext) as GraphContextType
  const { printContext } = useContext(PrintContext) as PrintContextType
  const { pixelRatio, title, frameNumber } = printContext

  const handleLog = () => {
    setIsLog(!isLog)
  }
  const logExportEvent = () => {
    //  https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
    const label = getLink() // TODO: max chars 500
    const value = downloadsClicked + 1
    ReactGA.event({
      category: 'User',
      action: 'Tapped Download',
      label,
      value,
    })
    setDownloadsClicked(value)
  }
  const logLinkCopyEvent = () => {
    const label = getLink() // TODO: max chars 500
    const value = linkCopyCount + 1
    ReactGA.event({
      category: 'User',
      action: 'Copied Link',
      label,
      value,
    })
    setLinkCopyCount(value)
  }

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      onLoadFile(files[0])
    }
  }

  const handleUpload = () => {
    setUploadStatus('uploading...')
    const multipolarJson = getManifest(printContext, graphControls, config)
    const filePath = `${config.formula}/${title}`
    const s3 = new S3({
      accessKeyId: S3_ACCESS_ID,
      secretAccessKey: S3_ACCESS_SECRET,
      region: '',
      // region: 'eu-north-1',
    })
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `${filePath}.json`,
      Body: multipolarJson,
      ContentType: 'text/json',
      ACL: 'public-read',
    }
    s3.upload(params)
      .on('httpUploadProgress', (evt) => {
        const percentUploaded = (evt.loaded * 100) / evt.total
        console.log('progress: ', percentUploaded)
      })
      .send((err: Error, data: any) => {
        if (err) {
          console.log('upload failed', err)
          setUploadStatus('failed')
        } else {
          const remoteUrl = data.Location
          console.log(`success: ${remoteUrl}`)
          setUploadStatus('done!')
          window.history.pushState({}, '', `/${filePath}`)
        }
        setTimeout(() => {
          setUploadStatus(READY_TO_UPLOAD)
        }, TOAST_MILLIS)
      })
  }

  const handleExport = () => {
    const { current } = stageRef
    if (current) {
      const uri = current.toDataURL({ pixelRatio })
      var link = document.createElement('a')
      link.download = `${title}.png`
      link.href = uri
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      logExportEvent()
      const multipolarJson = getManifest(printContext, graphControls, config)
      Download(multipolarJson, `${title}.json`, 'text/plain')
    }
  }
  const getLink = () => {
    const { pathname, origin } = window.location
    if (pathname.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, type, name] = pathname.split('/')
      return `${origin}/${type}/${name}/${frameNumber}`
    }
    // @ts-ignore
    const newUrl = new URL(window.location)
    attachConfigToUrl(newUrl, config)
    return newUrl.href
  }
  const link = getLink()
  const onCopyLink = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, TOAST_MILLIS)
    logLinkCopyEvent()
  }
  const onAutoResizeToggled = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAutoResize(event.target.checked)
  }
  const onToggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle()
    } else {
      console.warn('Fullscreen is not supported.')
    }
  }
  return (
    <div className="wheelActions">
      {isShowAdvanced && !isFullscreen && (
        <div>
          <button onClick={handleLog}>{isLog ? 'logging' : 'log'}</button>
          <input type="file" onChange={handleImport} />
          <button onClick={handleUpload}>{uploadStatus}</button>
          <button onClick={handleExport}>download</button>
          <a href={link}>{title || 'untitled'}</a>
        </div>
      )}
      <CopyToClipboard text={link} onCopy={onCopyLink}>
        <div className="playButtons">
          <button>
            <FontAwesomeIcon icon={faCopy} title="Share" />
          </button>
          {isCopied && <span className="copyConfirmation">link copied to clipboard!</span>}
        </div>
      </CopyToClipboard>
      <div className="playButtons">
        {isShowAdvanced && (
          <div className="setting">
            <input type="checkbox" checked={isAutoResize} onChange={onAutoResizeToggled} />
            <label>Fit to window</label>
          </div>
        )}
        <button onClick={onToggleFullScreen}>
          <FontAwesomeIcon icon={faMaximize} title="Full Screen" />
        </button>
      </div>
    </div>
  )
}

export default WheelActions

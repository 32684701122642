export enum Layout {
  flower = 'flower',
  flowerH = 'flowerH',
  knot = 'knot',
  knot2 = 'knot2',
  snowflake = 'snowflake',
  wave = 'wave',
  spiral = 'spiral',
  logspiral = 'logspiral',
}

export type ControlValueMap = { [key: string]: any }

export type Config = {
  step: number
  formula: Layout
  zoom: number
  vert: number
  horiz: number
  rot: number
  isFlip: boolean
  thetaX: number
  exp: number
  thetaX2: number
  exp2: number
  isFillShape: boolean
  isConstrainToCycle: boolean
  progress: number
  cycles: number
  strokeColor: string
  fillColor: string
  bgColor: string
  isUseBgColor: boolean
  isVisibleStroke: boolean
  strokeWidth: number
}

export type ControlType = {
  type: string
  label: string
  initialValue: string | number | boolean
  step?: number
  min?: number | string
  max?: number | string
  values?: { key: string; value: string }[]
  isAnimateEnabled?: boolean
  isAnimateRandom?: boolean
}
export type NormalizedControlType = {
  type?: string
  rangeStart: number
  initialValueSteps: number
  step: number
  numStepsInRange: number
  overflow: 'loop' | 'reverse'
}
export type NormalizedControlMap = { [key: string]: NormalizedControlType }

export type ControlStatusType = {
  stepDirection?: 1 | -1 // TODO: remove
  currFrame: number
  framesToPlay: number // -1 for all
  frameRate: number // millis for now, TODO: switch to frames per minute (aka bpm)
  isInterrupt: boolean
  controls: NormalizedControlMap
}

export type RawControlParam = {
  value: string
  step: string
  min: string
  max: string
}
export type RawControlParamMap = { [key: string]: string | RawControlParam }

export const THETA = '\u03b8'

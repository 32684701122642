import cx from 'classnames'
import React, { useEffect, useState, useContext } from 'react'
import ReactGA from 'react-ga'
import WheelWell from './WheelWell'

import './App.scss'
import { GraphContextProvider } from './GraphContext'
import {
  currentJsonVersion,
  getControlsFromJsonFile,
  getControlsFromStringParams,
} from './GraphParamDefs'
import { defaultMintConfig, PrintContextProvider } from './PrintContext'
import { RawControlParamMap } from './types'
import UserContext, { UserContextType } from './UserContext'

// @ts-ignore
const { S3_BUCKET_NAME, S3_REGION = 'eu-north-1' } = MULTIPOLAR_ENV
const S3_BUCKET_PATH = `https://${S3_BUCKET_NAME}.s3.${S3_REGION}.amazonaws.com`

const App = () => {
  const {
    userContext: { isFullscreen },
  } = useContext(UserContext) as UserContextType
  const [requestedFrameNumber, setRequestedFrameNumber] = useState(0)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const getInitialControlsFromPath = (pathname: string) => {
      const [, type, name, frame = '1'] = pathname.split('/')
      const url = `${S3_BUCKET_PATH}/${type}/${name}.json`
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((response) => {
            if (response.status !== 200) {
              reject(`Couldn't load ${pathname}`)
            }
            return response.text()
          })
          .then((fileText) => {
            getInitialControlsFromJson(fileText)
            const frameNumber = parseInt(frame)
            if (frameNumber >= 1) {
              setRequestedFrameNumber(frameNumber)
            }
            resolve('')
          })
      })
    }
    const {
      location: { pathname, search },
    } = window
    const pageview = `${pathname}${search}`
    ReactGA.pageview(pageview)
    if (search || pathname.length === 1) {
      const newUrl = new URL(window.origin)
      window.history.replaceState({}, '', newUrl) // hide our big url
      setIsReady(true)
    } else {
      getInitialControlsFromPath(pathname).catch((error) => {
        console.log(error)
        alert(error)
        setIsReady(true)
        window.history.replaceState({}, '', '/')
      })
    }
  }, [])

  const [initialMintConfig, setInitialMintConfig] = useState(defaultMintConfig)
  const getInitialControlsFromQuery = () => {
    var queryString = document.location.search.substring(1)
    const queryParamArray = queryString.split('&')
    const queryParamMap: RawControlParamMap = queryParamArray.reduce((acc, paramString) => {
      if (!paramString) {
        return acc
      }
      const [key, value] = paramString.split('=')
      return { ...acc, [key]: value }
    }, {})
    return getControlsFromStringParams(queryParamMap)
  }
  const [initialConfig, setInitialConfig] = useState(getInitialControlsFromQuery())

  const getInitialControlsFromJson = (json: string) => {
    const fileObject = JSON.parse(json)
    const { version, graph, position, colors, mintHistory } = fileObject
    if (version !== currentJsonVersion) {
      console.log('unsupported json')
      return
    }
    const newMintHistory = {
      ...defaultMintConfig,
      ...mintHistory,
    }
    const controlParams = { ...graph, ...position, ...colors }
    setInitialConfig(getControlsFromJsonFile(controlParams))
    setInitialMintConfig(newMintHistory)
    setIsReady(true)
  }
  const getInitialControlsFromFile = (selectedFile: File) => {
    setIsReady(false)
    const reader = new FileReader()
    reader.onload = (event) => {
      const fileStr = event.target ? event.target.result : ''
      console.log('loaded:', fileStr)
      if (fileStr) {
        getInitialControlsFromJson(fileStr.toString())
        window.history.replaceState({}, '', '/')
      }
    }
    reader.readAsText(selectedFile)
  }

  const { frameNumber: initialFrameNumber = 1 } = initialMintConfig
  const firstDisplayFrameNumber =
    requestedFrameNumber > 0 ? requestedFrameNumber : initialFrameNumber
  const className = cx('App', { fullScreen: isFullscreen })

  return (
    <div className={className}>
      <header className="App-header">
        {isReady && (
          <PrintContextProvider initialMintConfig={initialMintConfig}>
            <GraphContextProvider
              initialControls={initialConfig}
              firstFrameOffset={initialFrameNumber}
              firstDisplayFrameNumber={firstDisplayFrameNumber}
            >
              <WheelWell onLoadFile={getInitialControlsFromFile} />
            </GraphContextProvider>
          </PrintContextProvider>
        )}
      </header>
    </div>
  )
}

export default App

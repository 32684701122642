import React from 'react'

export interface IUserContext {
  isReadOnly: boolean
  isAdvanced: boolean
  isAutoResize: boolean
  isFullscreen: boolean
}

export type UserContextType = {
  userContext: IUserContext
  setIsReadOnly: (isReadOnly: boolean) => void
  setIsAdvanced: (isAdvanced: boolean) => void
  setIsAutoResize: (isAutoResize: boolean) => void
}

type UserContextProviderProps = {
  children?: React.ReactNode
  initialUserConfig: IUserContext
}

const UserContext = React.createContext<UserContextType | null>(null)

export const UserContextProvider = ({ children, initialUserConfig }: UserContextProviderProps) => {
  const [userContext, setUserContext] = React.useState<IUserContext>(initialUserConfig)

  const handleResize = () => {
    const appearsToBeFullScreen =
      // eslint-disable-next-line no-restricted-globals
      window.innerWidth === screen.width && screen.height - window.innerHeight < 50
    setIsFullscreen(appearsToBeFullScreen)
  }

  React.useEffect(() => {
    // Add a listener for the resize event to detect fullscreen changes (fullscreenchange not sufficient, so no point using)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  const setIsReadOnly = (isReadOnly: boolean) => {
    setUserContext((prevContext) => ({ ...prevContext, isReadOnly }))
  }
  const setIsAdvanced = (isAdvanced: boolean) => {
    setUserContext((prevContext) => ({ ...prevContext, isAdvanced }))
  }
  const setIsAutoResize = (isAutoResize: boolean) => {
    setUserContext((prevContext) => ({ ...prevContext, isAutoResize }))
  }
  const setIsFullscreen = (isFullscreen: boolean) => {
    setUserContext((prevContext) => ({ ...prevContext, isFullscreen }))
  }
  return (
    <UserContext.Provider
      value={{
        setIsAdvanced,
        setIsReadOnly,
        setIsAutoResize,
        userContext,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext

import React, { ReactNode } from 'react'
import { stopPropagation } from './key-handlers'

interface LabelBase {
  label: string
  value: any
  className?: string
  id?: string
  isReadOnly?: boolean
  onBlur?: any
  onChange?: any
  onClick?: any
  onKeyDown?: any
  onRef?: any
  onFocus?: any
}

interface TextInputWithLabelType extends LabelBase {
  rightLabel?: string
  isAutoFocus?: boolean
}

interface CheckboxWithLabelType extends LabelBase {
  disabled?: boolean
}

interface ImageWithLabelType extends LabelBase {
  src: string
  onImageRef: any
}

interface DivWithLabelType extends LabelBase {
  className: string
  children?: ReactNode
  rightLabel?: string
}

interface SelectWithLabelType extends LabelBase {
  itemArray: { value: string; name: string }[]
}

const TextInputWithLabel = ({
  id,
  className,
  label,
  rightLabel,
  isReadOnly,
  onBlur,
  onChange,
  onClick,
  onKeyDown = stopPropagation,
  value,
  onRef,
  onFocus,
}: TextInputWithLabelType) => {
  return (
    <div onClick={onClick}>
      {<label htmlFor={id}>{label ? `${label}: ` : ''}</label>}
      <input
        id={id}
        ref={onRef}
        className={className}
        type="text"
        readOnly={isReadOnly}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        defaultValue={value}
        onFocus={onFocus}
      />
      {<label htmlFor={id}>{rightLabel} </label>}
    </div>
  )
}
const ControlledTextInputWithLabel = ({
  id,
  label,
  rightLabel,
  isReadOnly,
  onBlur,
  onChange,
  onKeyDown = stopPropagation,
  value,
  onRef,
  isAutoFocus,
}: TextInputWithLabelType) => {
  return (
    <div>
      {<label htmlFor={id}>{label ? `${label}: ` : ''}</label>}
      <input
        id={id}
        ref={onRef}
        className="textInputWithLabel"
        type="text"
        readOnly={isReadOnly}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        autoFocus={isAutoFocus}
      />
      {<label htmlFor={id}>{rightLabel} </label>}
    </div>
  )
}

const CheckboxWithLabel = ({
  id,
  label,
  disabled: isDisabled,
  value,
  onChange,
}: CheckboxWithLabelType) => {
  return (
    <div className="labeledCheckbox">
      <input
        type="checkbox"
        id={id}
        defaultChecked={!!value}
        disabled={isDisabled}
        onChange={onChange}
      />
      <label htmlFor={id} className="handle">
        {label}
      </label>
    </div>
  )
}

const ImageWithLabel = ({ id, label, src, onImageRef, onClick }: ImageWithLabelType) => {
  return (
    <div>
      <label htmlFor={id}>{label ? `${label}: ` : ''}</label>
      <img
        id={id}
        ref={onImageRef}
        className="imageWithLabel"
        alt={label}
        src={src}
        onClick={onClick}
      />
    </div>
  )
}

const DivWithLabel = ({ id, label, value, className, children, rightLabel }: DivWithLabelType) => {
  return (
    <div>
      <label htmlFor={id}>{label ? `${label}: ` : ''}</label>
      <div id={id} className={className}>
        {value || children}
      </div>
      {<label htmlFor={id}>{rightLabel} </label>}
    </div>
  )
}

const SelectWithLabel = ({
  id,
  label,
  itemArray,
  value: selectedItemValue,
  onChange,
}: SelectWithLabelType) => {
  return (
    <div className="labeledSelect">
      <label htmlFor={id}>{label}</label>
      <select id={id} defaultValue={selectedItemValue} onChange={onChange}>
        {itemArray.map(({ value, name }) => (
          <option key={value} value={value}>
            {name !== undefined ? name : value}
          </option>
        ))}
      </select>
    </div>
  )
}

export {
  CheckboxWithLabel,
  ControlledTextInputWithLabel,
  DivWithLabel,
  ImageWithLabel,
  SelectWithLabel,
  TextInputWithLabel,
}

import React, { ChangeEvent, KeyboardEvent, useState } from 'react'
import cx from 'classnames'
import { incrementingKeyHandler } from './key-handlers'
import { ControlType } from './types'

interface Props {
  controlKey: string
  controlValue: any
  control: ControlType
  updateControl: (key: string, value: any) => void
  updateConfig: (key: string, param: string, value: any) => void
  isControlled: boolean
}

const GraphParam = ({
  control,
  controlValue: value,
  updateControl,
  updateConfig,
  controlKey,
  isControlled,
}: Props) => {
  const [isShowSettings, setIsShowSettings] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { label, type, step, min, max, values = [], isAnimateEnabled } = control
  // const { label, type, step, min, max, values = [], isAnimateEnabled, isAnimateRandom } = control
  const controlParamUpdated = (paramKey: string, value: any) => {
    updateConfig(controlKey, paramKey, value)
  }
  const updateCheckboxParam = (event: ChangeEvent<HTMLInputElement>, paramKey: string) => {
    const value = event.target.checked
    controlParamUpdated(paramKey, value)
  }
  const updateFloatParam = (event: ChangeEvent<HTMLInputElement>, paramKey: string) => {
    const value = parseFloat(event.currentTarget.value)
    if (!isNaN(value)) {
      controlParamUpdated(paramKey, value)
    }
  }
  const updateIsAnimateEnabled = (event: ChangeEvent<HTMLInputElement>) => {
    updateCheckboxParam(event, 'isAnimateEnabled')
  }
  // const updateIsAnimateRandom = (event: ChangeEvent<HTMLInputElement>) => {
  //   updateCheckboxParam(event, 'isAnimateRandom')
  // }
  const updateParamStep = (event: ChangeEvent<HTMLInputElement>) => {
    updateFloatParam(event, 'step')
  }
  const updateParamMin = (event: ChangeEvent<HTMLInputElement>) => {
    updateFloatParam(event, 'min')
  }
  const updateParamMax = (event: ChangeEvent<HTMLInputElement>) => {
    updateFloatParam(event, 'max')
  }
  const onStepKeydown = (event: KeyboardEvent) => {
    const updateControlValue = (value: any) => {
      controlParamUpdated('step', value)
    }
    incrementingKeyHandler(event, updateControlValue)
  }
  const onMinKeydown = (event: KeyboardEvent) => {
    const updateControlValue = (value: any) => {
      controlParamUpdated('min', value)
    }
    incrementingKeyHandler(event, updateControlValue)
  }
  const onMaxKeydown = (event: KeyboardEvent) => {
    const updateControlValue = (value: any) => {
      controlParamUpdated('max', value)
    }
    incrementingKeyHandler(event, updateControlValue)
  }

  const updateControlValue = (value: any) => {
    updateControl(controlKey, value)
  }
  // TODO
  // const onChange = (event: ChangeEvent<HTMLInputElement>) => {
  const onChange = (event: any) => {
    if (type === 'color' || type === 'select') {
      updateControlValue(event.target.value)
    } else if (type === 'checkbox') {
      const currValue = !!event.target.checked
      updateControlValue(currValue)
    } else {
      const currValue = parseFloat(event.target.value)
      if (!isNaN(currValue)) {
        updateControlValue(currValue)
      }
    }
  }
  const onEditFocus = () => {
    setIsEditing(true)
  }
  const onEditBlur = () => {
    setIsEditing(false)
  }
  const onSliderInteraction = () => {
    setIsDragging(true)
  }
  const onSliderEndInteraction = () => {
    setIsDragging(false)
  }
  const onKeydown = (event: KeyboardEvent) => {
    incrementingKeyHandler(event, updateControlValue)
  }
  const onLabelClick = () => {
    if (type === 'text') {
      setIsShowSettings(!isShowSettings)
    }
  }
  const getValueElement = () => {
    const isPropControlled = isControlled && isAnimateEnabled
    const valueElement = () => {
      if ((isDragging || isPropControlled) && type !== 'color') {
        return <div className="vizValue">{value}</div>
      }
      if (type === 'select') {
        return (
          <select defaultValue={value} onChange={onChange}>
            {values.map(({ key, value }) => (
              <option key={key} value={key}>
                {value !== undefined ? value : key}
              </option>
            ))}
          </select>
        )
      }
      return (
        <input
          type={type}
          defaultValue={value}
          defaultChecked={value}
          onChange={onChange}
          onKeyDown={onKeydown}
          readOnly={isPropControlled}
          onFocus={onEditFocus}
          onBlur={onEditBlur}
        />
      )
    }
    const sliderElement = () => {
      if (type === 'checkbox') {
        return
      }
      if (isEditing || isPropControlled) {
        // TODO: doesn't seem to prevent React controlled/uncontrolled warning, but seems to work
        return (
          <input
            className="scrubber"
            value={value}
            type="range"
            step={step}
            min={min}
            max={max}
            readOnly
          />
        )
      }
      return (
        <input
          className="scrubber"
          defaultValue={value}
          type="range"
          step={step}
          min={min}
          max={max}
          onChange={onChange}
          onMouseDown={onSliderInteraction}
          onMouseUp={onSliderEndInteraction}
          onTouchStart={onSliderInteraction}
          onTouchEnd={onSliderEndInteraction}
          onFocus={onSliderInteraction}
          onBlur={onSliderEndInteraction}
        />
      )
    }
    return (
      <div className="param">
        {valueElement()}
        {type !== 'color' && type !== 'select' && sliderElement()}
      </div>
    )
  }

  const labelClassname = cx({
    enabled: isAnimateEnabled,
    clickable: type === 'text',
  })

  return (
    <div key={`control-${controlKey}`} className="vizSetting">
      <div className="vizParamElement">
        <label className={labelClassname} onClick={onLabelClick}>{`${label}: `}</label>
        {getValueElement()}
      </div>
      {isShowSettings && (
        <div id="vizParamSettingsContainer" className="vizParamSettings">
          <fieldset>
            <legend>
              <input
                type="checkbox"
                defaultChecked={isAnimateEnabled}
                onChange={updateIsAnimateEnabled}
              />
              Animate
            </legend>
            <div className="viz-param-settings">
              {/*Random:{' '}*/}
              {/*<input*/}
              {/*  type="checkbox"*/}
              {/*  defaultChecked={isAnimateRandom}*/}
              {/*  onChange={updateIsAnimateRandom}*/}
              {/*/>*/}
              Step:{' '}
              <input
                type="text"
                defaultValue={step}
                onChange={updateParamStep}
                onKeyDown={onStepKeydown}
              />
              Min:{' '}
              <input
                type="text"
                defaultValue={min}
                onChange={updateParamMin}
                onKeyDown={onMinKeydown}
              />
              Max:{' '}
              <input
                type="text"
                defaultValue={max}
                onChange={updateParamMax}
                onKeyDown={onMaxKeydown}
              />
            </div>
          </fieldset>
        </div>
      )}
    </div>
  )
}

export default GraphParam

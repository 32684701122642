import Konva from 'konva'
import React, { useContext } from 'react'
import { Stage, Layer, Shape, Rect } from 'react-konva'
import cx from 'classnames'

import { currFormula, pinToSafeInt } from './Formulas'
import WheelGraphFooter from './WheelGraphFooter'
import GraphContext, { GraphContextType } from './GraphContext'
import PrintContext, { PrintContextType } from './PrintContext'

interface Props {
  width: number
  toggleIsAnimate: () => void
  isEditing: boolean
}
/*
  - image must be invariant at any image width, i.e. actual zoom (and pan) must
  be calibrated to image width.
  - choice of clockwise/counter-clockwise and flip direction is arbitrary, in
   that there are only eight distinct orientations (pure reflection or rotation into each quadrant), so:
    - default to ROTATE_CCW and REFLECT_HORIZ
    - // TODO: support user-selected anyway, which i guess unlocks arbitrary perspective support
 */
const REFERENCE_WIDTH = 100

const WheelGraph = ({ width, toggleIsAnimate, isEditing }: Props) => {
  const { printContext } = useContext(PrintContext) as PrintContextType
  const {
    graphContext: { graphConfig: config, graphRef: stageRef, isLog, frameCount },
  } = useContext(GraphContext) as GraphContextType
  const {
    strokeColor,
    strokeWidth,
    fillColor,
    step,
    progress: thetaRange,
    zoom,
    vert,
    horiz,
    rot: rotationDegrees,
    bgColor,
    cycles,
    isUseBgColor,
    isFillShape,
    isVisibleStroke,
    isFlip,
  } = config
  const rotationRads = (-rotationDegrees * Math.PI) / 180
  const plotter = currFormula(config)
  const { isPolar, isWantRadians } = plotter
  const label = plotter.label()
  const actualZoom = (zoom * plotter.scale() * width) / REFERENCE_WIDTH
  const offsetFactor = plotter.offset()
  const offsetX = width / 2 - offsetFactor.x * actualZoom + horiz
  const offsetY = width / 2 - offsetFactor.y * actualZoom + vert
  const actualOffsetX = offsetFactor.x * actualZoom
  const actualOffsetY = offsetFactor.y * actualZoom
  const flipFactor = { x: 1, y: isFlip ? -1 : 1 }
  const rotate = (x: number, y: number, radians: number) => {
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)
    const nx = cos * (x - actualOffsetX) + sin * (y - actualOffsetY) + actualOffsetX
    const ny = cos * (y - actualOffsetY) - sin * (x - actualOffsetX) + actualOffsetY
    return { x: nx, y: ny }
  }
  const reflect = (x: number, y: number) => {
    return {
      x: flipFactor.x * x,
      y: flipFactor.y * y,
    }
  }
  const polarPlot = (r: number, theta: number) => {
    const rotatedX = actualZoom * r * Math.cos(theta + rotationRads)
    const rotatedY = actualZoom * r * Math.sin(theta + rotationRads)
    return reflect(rotatedX, rotatedY)
  }
  const xyPlot = (r: number, degrees: number) => {
    const x = actualZoom * (degrees % 360)
    const y = actualZoom * r
    const { x: rotatedX, y: rotatedY } = rotate(x, y, rotationRads)
    return reflect(rotatedX, rotatedY)
  }
  const plotFunc = isPolar ? polarPlot : xyPlot
  const drawIt = (context: Konva.Context) => {
    if (isLog) {
      console.info(
        `[degrees (theta), r] [x,y] for: ${label} -- step: ${step} zoom: ${zoom} offset: ${offsetX},${offsetY}`
      )
      console.info(`formula: ${plotter.formula}`)
    }
    const degreeStep = step / Math.max(1, cycles)
    const degreeRange = step * thetaRange
    for (let degrees = 0; degrees < degreeRange; degrees += degreeStep) {
      const theta = (degrees * Math.PI) / 180
      const r = plotter.formula(isWantRadians ? theta : degrees)
      const { x, y } = plotFunc(r, isPolar ? theta : degrees % 360)
      if (isLog) {
        console.info(`[${degrees} (${theta}), ${r}] [${x},${y}]`)
      }
      const targetX = pinToSafeInt(x + offsetX)
      const targetY = pinToSafeInt(y + offsetY)
      if (degrees === 0) {
        context.moveTo(targetX, targetY)
      } else {
        context.lineTo(targetX, targetY)
      }
    }
  }
  const sceneFunc = (context: Konva.Context, shape: Konva.Shape) => {
    if (isFillShape) {
      context.beginPath()
      drawIt(context)
      context.closePath()
      context.fillStrokeShape(shape)
    } else {
      context.beginPath()
      context.closePath()
      drawIt(context)
      context.strokeShape(shape)
    }
  }
  const onClick = () => {
    if (isEditing) {
      // ignore; too easy to trigger when clicking out of an input (esp. color picker)
    } else {
      toggleIsAnimate()
    }
  }
  const stageClass = cx('stage', { clickable: !isEditing && frameCount > 1 })
  return (
    <>
      <Stage
        width={width}
        height={width}
        className={stageClass}
        ref={stageRef}
        onClick={onClick}
        onTouchStart={onClick}
      >
        <Layer>
          {isUseBgColor && <Rect width={width} height={width} fill={bgColor}></Rect>}
          <Shape
            sceneFunc={sceneFunc}
            fill={fillColor}
            stroke={isVisibleStroke ? strokeColor : bgColor}
            strokeWidth={strokeWidth}
          />
        </Layer>
        <WheelGraphFooter printContext={printContext} config={config} width={width} />
      </Stage>
    </>
  )
}

export default WheelGraph

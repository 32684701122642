import React, { useContext, useEffect, useState } from 'react'
import VizSettings from './VizSettings'
import WheelGraph from './WheelGraph'
import GraphContext, { GraphContextType } from './GraphContext'
import UserContext, { UserContextType } from './UserContext'
import PrintContext, { PrintContextType } from './PrintContext'
import WheelActions from './WheelActions'
import PlayControls from './PlayControls'

const GRAPH_SIZE_PERCENTAGE = 0.9
const CONTROLS_WIDTH = 280
const WHEEL_ACTIONS_HEIGHT = 22
const BREAKPOINT1 = 800

type Props = { onLoadFile: (file: File) => void }

const WheelWell = ({ onLoadFile }: Props) => {
  const {
    userContext: { isReadOnly, isAdvanced, isAutoResize, isFullscreen },
  } = useContext(UserContext) as UserContextType
  const [isShowSettings, setIsShowSettings] = useState(!isReadOnly)
  const isStackVertical = window.matchMedia(`( max-width: ${BREAKPOINT1}px )`).matches
  const {
    graphContext: { graphControls: controls, frameCount, frameNumber },
    setFrame,
    updateControlValue,
    updateControlParam,
  } = useContext(GraphContext) as GraphContextType
  const {
    setImageSize,
    setIsAnimate,
    setAnimationType,
    setAnimationMillis,
    setFrameNumber,
    printContext: {
      isAnimate,
      animationType,
      imageSize: { width: imageWidth },
      animationMillis,
    },
  } = useContext(PrintContext) as PrintContextType
  const calcSize = () => {
    const maxWidth = window.innerWidth - (isShowSettings && !isStackVertical ? CONTROLS_WIDTH : 0)
    const minDim = Math.min(maxWidth, window.innerHeight - WHEEL_ACTIONS_HEIGHT)
    const newSize = Math.floor(GRAPH_SIZE_PERCENTAGE * minDim)
    return newSize
  }
  const size = isAutoResize ? calcSize() : imageWidth
  if (size !== imageWidth) {
    setTimeout(() => {
      setImageSize(size)
    }, 200)
  }

  useEffect(() => {
    if (!isAutoResize) {
      return
    }
    let timeout: NodeJS.Timeout
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        const newSize = calcSize()
        setImageSize(newSize)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })
  const onToggleSettings = () => {
    setIsShowSettings(!isShowSettings)
  }
  const [animationLoopId, setAnimationLoopId] = useState<NodeJS.Timer>()

  const startAnimation = (animationMillis: number, isForce?: boolean) => {
    // const animationMillis = frameRate / 60 / 100 // TODO: switch to frameRate
    if (!animationLoopId || isForce) {
      clearTimeout(animationLoopId)
      setIsAnimate(true)
      let nextFrameNumber = frameNumber
      let isAscending = true

      const id = setInterval(() => {
        if (animationType === 1) {
          if (isAscending && nextFrameNumber === frameCount) {
            isAscending = false
            nextFrameNumber--
          } else if (!isAscending && nextFrameNumber === 1) {
            isAscending = true
            nextFrameNumber++
          } else {
            nextFrameNumber = nextFrameNumber + (isAscending ? 1 : -1)
          }
        } else {
          nextFrameNumber = (nextFrameNumber % frameCount) + 1
        }
        setFrame(nextFrameNumber)
        setFrameNumber(nextFrameNumber)
      }, animationMillis)
      // console.log(`(re)starting animation at ${animationMillis}`)
      setAnimationLoopId(id)
    }
  }
  const stopAnimation = () => {
    clearTimeout(animationLoopId)
    setIsAnimate(false)
    setAnimationLoopId(undefined)
  }

  const toggleIsAnimate = () => {
    const newIsAnimate = !isAnimate
    if (!newIsAnimate) {
      stopAnimation()
    } else {
      startAnimation(animationMillis)
    }
    setIsAnimate(newIsAnimate)
  }
  const toggleIsReverse = () => {
    setAnimationType(animationType ? 0 : 1)
  }
  const updateAnimationMillis = (newAnimationMillis: number) => {
    if (newAnimationMillis !== animationMillis && isAnimate) {
      startAnimation(newAnimationMillis, true)
    }
    setAnimationMillis(newAnimationMillis)
  }

  return (
    <div className="wheelWell">
      {isShowSettings && (
        <VizSettings
          controls={controls}
          updateControl={updateControlValue}
          updateConfig={updateControlParam}
        />
      )}
      <div className="mainPanel">
        <div className="controlBar">
          <div className="appTitle">Multipolar</div>
          <div className="viewOptions">
            {!isReadOnly && (
              <button className="controlsToggle" onClick={onToggleSettings}>
                {isShowSettings ? 'hide' : 'show'} controls
              </button>
            )}
            {frameCount > 1 && !isFullscreen && (
              <PlayControls
                toggleIsReverse={toggleIsReverse}
                toggleIsAnimate={toggleIsAnimate}
                updateAnimationMillis={updateAnimationMillis}
              />
            )}
          </div>
        </div>
        <WheelGraph width={size} toggleIsAnimate={toggleIsAnimate} isEditing={isShowSettings} />
        <WheelActions isShowAdvanced={isAdvanced} onLoadFile={onLoadFile} />
      </div>
    </div>
  )
}

export default WheelWell

import { Config, Layout, THETA } from './types'
import { formatFloat, graphParamGroupOrder, graphParamGroups } from './GraphParamDefs'

type Plot2DFunction = (theta: number) => number
type LabelFunction = () => string
type Point = { x: number; y: number }

abstract class AbstractPlot {
  abstract formula: Plot2DFunction
  abstract label: LabelFunction
}

export class PlotBase implements AbstractPlot {
  config: Config
  constructor(config: Config) {
    this.config = config
  }
  formula(theta: number): number {
    return 0
  }
  offset(): Point {
    return { x: 0, y: 0 }
  }
  scale(): number {
    return 0.1
  }
  label(): string {
    return 'empty plot'
  }
  get isPolar() {
    return true
  }
  get isWantRadians() {
    return true
  }
  static getParamGroups(excludedParamKeys: string[] = [], includedParamKeys: string[] = []) {
    return graphParamGroupOrder.map((groupKey) => {
      // @ts-ignore
      const { name, params, extraParams = [] } = graphParamGroups[groupKey]
      return {
        name,
        params: params.filter((key: string) => {
          const excluded = extraParams.includes(key) || excludedParamKeys.includes(key)
          return includedParamKeys.includes(key) || !excluded
        }),
      }
    })
  }
  static paramGroups() {
    return PlotBase.getParamGroups()
  }
}
const formatFunction = (fn: string, exp: number, multiplier: number, arg: string) => {
  const isAbsOne = Math.abs(multiplier) === 1
  const multiplierPart = isAbsOne ? `${multiplier < 0 ? '-' : ''}` : formatFloat(multiplier)
  const expPart = exp === 1 ? '' : `^${formatFloat(exp)}`
  return `${fn}${expPart}(${multiplierPart}${arg})`
}
const RAD_THETA = `rad${THETA}`

class FlowerPlot extends PlotBase {
  formula(theta: number): number {
    const { thetaX, exp } = this.config
    const thetaMultiplier = thetaX / 2
    const radius = Math.pow(Math.cos(thetaMultiplier * theta), exp)
    return pinToSafeInt(radius)
  }
  label() {
    const { thetaX, exp } = this.config
    if (exp === 0) {
      return 'r=1'
    }
    const cosPart = formatFunction('cos', Math.abs(exp), thetaX / 2, RAD_THETA)
    if (exp < 0) {
      return `r=1/${cosPart}`
    }
    return `r=${cosPart}`
  }
}

// TODO: weird scaling issues, barely usable, but probly worth exploring...
class HyperFlowerPlot extends PlotBase {
  formula(theta: number): number {
    const { thetaX, exp } = this.config
    const thetaMultiplier = thetaX / 2
    const radius = Math.pow(Math.cosh(thetaMultiplier * theta), exp)
    return pinToSafeInt(radius)
  }
  label() {
    const { thetaX, exp } = this.config
    if (exp === 0) {
      return 'r=1'
    }
    const cosPart = formatFunction('cosh', Math.abs(exp), thetaX / 2, RAD_THETA)
    if (exp < 0) {
      return `r=1/${cosPart}`
    }
    return `r=${cosPart}`
  }
}
class KnotPlot extends PlotBase {
  static paramGroups() {
    return PlotBase.getParamGroups([], ['thetaX2', 'exp2'])
  }
  formula(theta: number): number {
    const { thetaX, exp, thetaX2, exp2 } = this.config
    const thetaMultiplier = thetaX / 2
    const thetaMultiplier2 = thetaX2 / 2
    const radius =
      Math.pow(Math.cos(thetaMultiplier * theta), exp) *
      Math.pow(Math.sin(thetaMultiplier2 * theta), exp2)
    return pinToSafeInt(radius)
  }
  label() {
    const { thetaX, exp, thetaX2, exp2 } = this.config
    if (exp === 0 && exp2 === 0) {
      return 'r=1'
    }
    const cosPart = formatFunction('cos', Math.abs(exp), thetaX / 2, RAD_THETA)
    const sinPart = formatFunction('sin', Math.abs(exp2), thetaX2 / 2, RAD_THETA)
    if (exp < 0 && exp2 < 0) {
      return `r=1/${cosPart}${sinPart}`
    }
    return `r=${cosPart}${exp2 < 0 ? '/' : ''}${sinPart}`
  }
}
class KnotPlot2 extends PlotBase {
  static paramGroups() {
    return PlotBase.getParamGroups([], ['thetaX2', 'exp2'])
  }
  formula(theta: number): number {
    const { thetaX, exp, thetaX2, exp2 } = this.config
    const thetaMultiplier = thetaX / 2
    const thetaMultiplier2 = thetaX2 / 2
    const radius =
      Math.pow(Math.sin(thetaMultiplier * theta), exp) *
      Math.pow(Math.sin(thetaMultiplier2 * theta), exp2)
    return pinToSafeInt(radius)
  }
  label() {
    const { thetaX, exp, thetaX2, exp2 } = this.config
    if (exp === 0 && exp2 === 0) {
      return 'r=1'
    }
    const sinPart = formatFunction('sin', Math.abs(exp), thetaX / 2, RAD_THETA)
    const sinPart2 = formatFunction('sin', Math.abs(exp2), thetaX2 / 2, RAD_THETA)
    if (exp < 0 && exp2 < 0) {
      return `r=1/${sinPart}${sinPart2}`
    }
    return `r=${sinPart}${exp2 < 0 ? '/' : ''}${sinPart2}`
  }
}

class SnowflakePlot extends PlotBase {
  static paramGroups() {
    return PlotBase.getParamGroups(['exp'])
  }
  formula(theta: number): number {
    const { thetaX: thetaMultiplier } = this.config
    const radius = Math.log(Math.abs(Math.cos(thetaMultiplier * theta)))

    return radius
  }
  label() {
    const { thetaX } = this.config
    const cosPart = formatFunction('cos', 1, thetaX, RAD_THETA)

    return `r=ln(|${cosPart}|)`
  }
}
class WavePlot extends PlotBase {
  get isPolar() {
    return false
  }
  scale(): number {
    return 0.006
  }
  get isWantRadians() {
    return true
  }
  offset() {
    return { x: 180, y: 0 }
  }
  formula(theta: number): number {
    const { thetaX, exp } = this.config
    const thetaMultiplier = thetaX / 2
    const y = 10 * exp * Math.sin(thetaMultiplier * theta)
    return pinToSafeInt(y)
  }
  label() {
    const { thetaX, exp } = this.config
    const thetaMultiplier = formatFloat(thetaX / 2)
    return `y=${10 * exp}sin(${thetaMultiplier}rad(x))`
  }
}
class SpiralPlot extends PlotBase {
  get isWantRadians() {
    return false
  }
  formula(degrees: number): number {
    const { thetaX, exp, isConstrainToCycle } = this.config
    const constrainedDegrees = isConstrainToCycle ? degrees % (360 * thetaX) : degrees
    const rads = (constrainedDegrees * Math.PI) / 180
    const radius = Math.pow(rads, exp)

    return pinToSafeInt(radius)
  }
  label() {
    const { thetaX, exp, isConstrainToCycle } = this.config
    const expPart = exp === 1 ? '' : `^${formatFloat(exp)}`
    const modPart = thetaX === 1 ? 'mod360' : `mod(360*${formatFloat(thetaX)})`
    const constrainedDegrees = isConstrainToCycle ? `(${THETA}${modPart})` : THETA
    return `r=rad${constrainedDegrees}${expPart}`
  }
}
class LogSpiralPlot extends PlotBase {
  get isWantRadians() {
    return false
  }
  formula(degrees: number): number {
    const { thetaX, exp, isConstrainToCycle } = this.config
    const constrainedDegrees = isConstrainToCycle ? degrees % (360 * thetaX) : degrees
    const rads = (constrainedDegrees * Math.PI) / 180
    const radius = Math.pow(Math.E, exp * rads)

    return pinToSafeInt(radius)
  }
  label() {
    const { thetaX, exp, isConstrainToCycle } = this.config
    if (thetaX === 0) {
      return 'r=1'
    }
    const modPart = thetaX === 1 ? 'mod360' : `mod(360*${formatFloat(thetaX)})`
    const constrainedDegrees = isConstrainToCycle ? `${THETA}${modPart}` : THETA
    const expFactor = exp === 1 ? '' : `${formatFloat(exp)}`
    const expPart =
      exp === 1 ? `rad(${constrainedDegrees})` : `${expFactor}rad(${constrainedDegrees})`
    return `r=e^${expPart}`
  }
}
const plotMap = {
  [Layout.flower]: FlowerPlot,
  [Layout.flowerH]: HyperFlowerPlot,
  [Layout.knot]: KnotPlot,
  [Layout.knot2]: KnotPlot2,
  [Layout.snowflake]: SnowflakePlot,
  [Layout.wave]: WavePlot,
  [Layout.spiral]: SpiralPlot,
  [Layout.logspiral]: LogSpiralPlot,
}

export const currFormula = (config: Config): PlotBase => {
  const { formula } = config
  return new plotMap[formula](config)
}

export const currParamGroups = (config: Config) => {
  const { formula } = config
  return plotMap[formula].paramGroups()
}

const NaNSubstitute = 0 // NaN // 0 // Infinity // TODO: link examples..
const isPinToSafeInts = true // TODO: needed?

export const pinToSafeInt = (coord: number) => {
  if (isNaN(coord)) {
    // console.log('pinning NaN')
    return NaNSubstitute
  }
  if (isPinToSafeInts) {
    if (coord > Number.MAX_SAFE_INTEGER) {
      // console.log(`pinning big ${coord}`)
      return Number.MAX_SAFE_INTEGER
    }
    if (coord < Number.MIN_SAFE_INTEGER) {
      // console.log(`pinning tiny ${coord}`)
      return Number.MIN_SAFE_INTEGER
    }
  }
  return coord
}

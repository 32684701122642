import { Config } from './types'
import { formatFloat } from './GraphParamDefs'
import { currFormula } from './Formulas'

export const formattedTimeStamp = (timestamp: number, isIncludeYear?: boolean) => {
  const date = new Date(timestamp)
  const year = date.getUTCFullYear()
  const padNum = (num: number): string => num.toString().padStart(2, '0')
  const month = padNum(date.getUTCMonth() + 1)
  const day = padNum(date.getUTCDate())
  const hour = padNum(date.getUTCHours())
  const minute = padNum(date.getUTCMinutes())
  const yearPart = isIncludeYear ? `${year}-` : ''
  const timezone = isIncludeYear ? ' GMT' : ' '
  return `${yearPart}${month}-${day} ${hour}:${minute}${timezone}`
}

export const getFormulaText = (config: Config): string => {
  const {
    strokeColor,
    fillColor,
    step,
    progress: thetaRange,
    zoom,
    vert,
    horiz,
    bgColor,
    cycles,
    isUseBgColor,
    isFillShape,
    isVisibleStroke,
    strokeWidth,
    rot: rotation,
    isFlip,
  } = config
  const plotter = currFormula(config)
  const { isPolar } = plotter
  const label = plotter.label()
  const stepStr = formatFloat(step)
  const progressStr = formatFloat(thetaRange)
  const degreeStepStr = cycles > 1 ? `${stepStr}/${formatFloat(cycles)}` : stepStr
  const rangeEnd = step === 1 ? progressStr : `${progressStr}*${stepStr}`
  const range = `${isPolar ? '\u03b8' : 'x'}=0..${rangeEnd} \u03b4=${degreeStepStr}`
  const where = `(${horiz},${vert},${zoom},${rotation}${isFlip ? '§' : ''})`
  const stroke = `${isVisibleStroke ? strokeColor.substring(1) : ''}`
  const strokeInfo = strokeWidth !== 1.0 ? ` ${formatFloat(strokeWidth)}` : ''
  const fill = `${isFillShape ? fillColor.substring(1) : ''}`
  const bg = `${isUseBgColor ? bgColor.substring(1) : ''}`
  const colors = `${stroke}.${bg}.${fill}${strokeInfo}`
  const legend = `${label} ${range} ${where} ${colors}`
  return legend
}

import {
  faBackward,
  faForward,
  faYinYang,
  faPause,
  faPlay,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useRef,
  useEffect,
  useState,
  createRef,
} from 'react'
import GraphContext, { GraphContextType } from './GraphContext'
import { DivWithLabel, SelectWithLabel, TextInputWithLabel } from './InputWithLabel'
import PrintContext, { PrintContextType } from './PrintContext'
import { incrementingKeyHandler } from './key-handlers'

type Props = {
  toggleIsReverse: () => void
  toggleIsAnimate: () => void
  updateAnimationMillis: (newAnimationMillis: number) => void
}
const PlayControls = ({ toggleIsAnimate, toggleIsReverse, updateAnimationMillis }: Props) => {
  const {
    printContext: { isAnimate, animationMillis, animationType },
    setIsAnimate,
    setFrameNumber,
  } = useContext(PrintContext) as PrintContextType
  const {
    setFrame,
    setFirstFrameNumber,
    graphContext: { frameCount, frameNumber },
    updateControlParam,
  } = useContext(GraphContext) as GraphContextType
  const isFocusFrameNumber = useRef(false)
  const tempoFieldRef = createRef<HTMLInputElement>()
  const [secondsPerAnimationUnit, setSecondsPerAnimationUnit] = useState(1)
  const isReverse = animationType !== 0

  const loadFrame = (nextFrameNumber: number) => {
    setIsAnimate(true)
    nextFrameNumber = ((nextFrameNumber - 1) % frameCount) + 1
    setTimeout(() => {
      setFrame(nextFrameNumber)
      setFrameNumber(nextFrameNumber)
      setIsAnimate(false)
    }, 100)
  }
  const onRefChange = React.useCallback((node: any) => {
    if (node && isFocusFrameNumber.current) {
      node.focus()
    }
  }, [])

  useEffect(() => {
    const handleKey = (event: unknown) => {
      const keyEvent = event as KeyboardEvent
      switch (keyEvent.keyCode) {
        case 32: // SPACE key
          toggleIsAnimate()
          if (!isAnimate) {
            isFocusFrameNumber.current = true
          }
          keyEvent.preventDefault()
          break
        default:
      }
    }
    window.addEventListener('keydown', handleKey)
    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  })

  const playText = isAnimate ? 'Pause' : 'Play'
  const playIcon = <FontAwesomeIcon icon={isAnimate ? faPause : faPlay} title={playText} />
  const onPlayClick = () => {
    toggleIsAnimate()
    if (!isAnimate) {
      isFocusFrameNumber.current = true
    }
  }
  // const onRewindClick = () => {}
  // const onScrubberInput = (event: any) => {
  //   // rewindTo(event.target.value)
  // }
  const onPrevClick = () => {
    loadFrame(frameNumber + frameCount - 1) // so underflow cycles
  }
  const onNextClick = () => {
    loadFrame(frameNumber + 1)
  }
  const getFrameNumberControl = () => {
    if (isAnimate) {
      return (
        <DivWithLabel
          id="frameNumber"
          label=""
          className="frameNum"
          value={frameNumber}
          rightLabel={`of ${frameCount}`}
        />
      )
    }
    const onKeyDown = (event: KeyboardEvent) => {
      incrementingKeyHandler(event, (newValue: number) => {
        seekToFrame(newValue)
        event.preventDefault()
      })
    }
    const seekToFrame = (newValue: number) => {
      if (newValue > 0 && newValue !== frameNumber) {
        loadFrame(newValue)
      }
    }
    const onFrameNumberBlur = (event: ChangeEvent<HTMLInputElement>) => {
      const newFrameNumber = parseInt(event.currentTarget.value)
      seekToFrame(newFrameNumber)
      isFocusFrameNumber.current = false
    }
    const onFrameNumberFocus = () => {
      isFocusFrameNumber.current = true
    }
    return (
      <TextInputWithLabel
        label=""
        value={frameNumber}
        onFocus={onFrameNumberFocus}
        onBlur={onFrameNumberBlur}
        rightLabel={`of ${frameCount}`}
        onKeyDown={onKeyDown}
        onRef={onRefChange}
      />
    )
  }
  const getFrameRateControl = () => {
    const onChangeAnimationValue = (event: ChangeEvent<HTMLInputElement>) => {
      const newFrameRate = parseFloat(event.currentTarget.value)
      if (!isNaN(newFrameRate)) {
        const newAnimationMillis = Math.trunc((1000 * secondsPerAnimationUnit) / newFrameRate)
        updateAnimationMillis(newAnimationMillis)
      }
    }
    const onChangeAnimationUnit = (event: ChangeEvent<HTMLSelectElement>) => {
      const newSecondsPerAnimationValue = parseInt(event.currentTarget.value)
      if (!isNaN(newSecondsPerAnimationValue) && tempoFieldRef.current) {
        const newTempoValue = (animationMillis / 1000) * newSecondsPerAnimationValue
        tempoFieldRef.current.value = String(newTempoValue)
        setSecondsPerAnimationUnit(newSecondsPerAnimationValue)
      }
    }
    const onKeyDown = (event: KeyboardEvent) => {
      incrementingKeyHandler(event, (newFrameRate: number) => {
        const newAnimationMillis = Math.trunc((1000 * secondsPerAnimationUnit) / newFrameRate)
        updateAnimationMillis(newAnimationMillis)
        event.preventDefault()
      })
    }
    return (
      <>
        <div className="tempo">
          <TextInputWithLabel
            onRef={tempoFieldRef}
            label=""
            value={animationMillis / 1000}
            onChange={onChangeAnimationValue}
            onKeyDown={onKeyDown}
          />
        </div>
        <SelectWithLabel
          itemArray={[
            // { name: 'ms', value: '.001' },
            { name: 'sec', value: '1' },
            { name: 'min', value: '60' },
            { name: 'hr', value: '3600' },
          ]}
          label="frames/"
          value={secondsPerAnimationUnit}
          onChange={onChangeAnimationUnit}
        />
      </>
    )
  }
  const onRewind = (event: React.MouseEvent) => {
    if (event.altKey) {
      updateControlParam('', '', '') // TODO: better way to force reset of animation controls
      setFrameNumber(1)
      setFirstFrameNumber(1)
    } else {
      loadFrame(1)
    }
  }
  const onToggleReverse = (event: React.MouseEvent) => {
    toggleIsReverse()
  }
  return (
    <div className="playControls">
      <div className="playButtons">
        <button onClick={onPrevClick} disabled={isAnimate}>
          <FontAwesomeIcon icon={faBackward} title="Previous Frame" />
        </button>
        <button
          onClick={onRewind}
          aria-label="restart"
          // disabled={frameNumber === 1}
        >
          <FontAwesomeIcon icon={faUndoAlt} title="Rewind to Start" />
        </button>
        <button id="play" aria-label={playText} onClick={onPlayClick}>
          {playIcon}
        </button>
        <button onClick={onNextClick} disabled={isAnimate}>
          <FontAwesomeIcon icon={faForward} title="Next Frame" />
        </button>
      </div>
      <div className="animationControls">
        {getFrameNumberControl()}
        <button
          className={isReverse ? 'selected' : ''}
          onClick={onToggleReverse}
          aria-label="reverse"
        >
          <FontAwesomeIcon icon={faYinYang} title={`${isReverse} ? 'Rewind':'Reverse'`} />
        </button>
        {getFrameRateControl()}
      </div>
    </div>
  )
}

export default PlayControls

const kmiSvg =
  '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">\n' +
  '    <path d="M 0 0 L 4 0  L 4 7  L 11 0  L 17 0  L 5 12  L 28 35  L 22 35  L 4 17  L 4 38  L 0 42" fill-opacity="1" fill="white"/>\n' +
  '    <path d="M 21 11  L 30 20  L 39 11  L 39 25  L 34 30  L 34 21  L 30 25  L 26 21  L 26 30  L 21 26" fill-opacity="1" fill="white"/>\n' +
  '    <path d="M 20 0  L 47 0  L 47 4  L 39 4  L 39 8  L 34 13  L 34 4  L 16 4" fill-opacity="1" fill="white"/>\n' +
  '    <path d="M 7 38  L 34 38  L 34 33  L 39 28  L 39 38  L 47 38  L 47 42  L 3 42" fill-opacity="1" fill="white"/>\n' +
  '</svg>'

const kmiSvgSize = { x: 50, y: 44 }

export { kmiSvg, kmiSvgSize }

import React from 'react'
import { Layer, Text, Image } from 'react-konva'
import useImage from 'use-image'

import { IPrintContext } from './PrintContext'
import { formattedTimeStamp, getFormulaText } from './util'
import { kmiSvg, kmiSvgSize } from './kmi' // TODO: try again to get kmi.svg to load as text (raw-loader https://stackoverflow.com/questions/52044410/)
import { Config } from './types'

const LEGEND_PERCENT = 0.35
const SIG_PERCENT = 0.35
const SIG_FADE_PERCENT = 0.8

interface Props {
  width: number
  config: Config
  printContext: IPrintContext // can't use it directly cuz we're not a child of a child of the provider?
}

const WheelGraphFooter = ({ printContext, config, width }: Props) => {
  const {
    timestamp,
    title,
    isShowFormula,
    isShowTitle,
    isShowTimestamp,
    isShowSignature,
    crawlerTextColor,
  } = printContext
  const legend = getFormulaText(config)
  const timestamp2 = formattedTimeStamp(timestamp)
  const year = new Date(timestamp).getUTCFullYear()
  const footerLineHeight = width * 0.035
  const footerHeight = 3 * footerLineHeight
  const footerTop = width - footerHeight
  const fontSize = footerLineHeight * 0.5
  const padding = footerLineHeight / 2
  const getSig = () => {
    const svgPercent = SIG_PERCENT * 0.1
    const scale = (width * svgPercent) / kmiSvgSize.x
    const text1 = `${isShowTitle ? title : ''} ${isShowTimestamp ? `${timestamp2}` : ''}`
    const text2 = `'${year.toString().substring(2, 4)}`
    const text2Left = width - fontSize * 2 - padding
    const imageLeft = width - width * svgPercent - scale * 44
    const titleWidth = width * (SIG_PERCENT - svgPercent)
    const titleLeft = width * 0.635
    const SignatureImage = () => {
      const svgSafeColor = crawlerTextColor.replace('#', '%23')
      const coloredSvg = kmiSvg.replace(/fill="white"/g, `fill="${svgSafeColor}"`)
      const fadedSvg = coloredSvg.replace(/fill-opacity="1"/g, `fill-opacity="${SIG_FADE_PERCENT}"`)
      const [image] = useImage(`data:image/svg+xml;utf8,${fadedSvg}`)
      const imageTop = footerTop + footerHeight - kmiSvgSize.y * scale - padding * 0.5
      return <Image image={image} x={imageLeft} y={imageTop} scale={{ x: scale, y: scale }} />
    }
    return (
      <>
        <Text
          text={text1}
          x={titleLeft}
          align="right"
          y={footerTop}
          width={titleWidth}
          height={footerHeight}
          lineHeight={1.0}
          verticalAlign="bottom"
          fontSize={fontSize * 0.8}
          padding={padding}
          fill={crawlerTextColor}
          fontStyle="italic"
        />
        <SignatureImage />
        <Text
          text={text2}
          x={text2Left}
          align="left"
          y={footerTop}
          width={text2Left}
          height={footerHeight}
          lineHeight={1.0}
          verticalAlign="bottom"
          fontSize={fontSize * 0.8}
          padding={padding}
          fill={crawlerTextColor}
        />
      </>
    )
  }
  return (
    <Layer>
      {isShowFormula && (
        <Text
          text={legend}
          x={0}
          align="left"
          y={footerTop}
          width={width * LEGEND_PERCENT}
          height={footerHeight}
          lineHeight={1.0}
          verticalAlign="bottom"
          fontSize={fontSize * 0.8}
          padding={padding}
          fill={crawlerTextColor}
        />
      )}
      {isShowSignature && getSig()}
    </Layer>
  )
}

export default WheelGraphFooter

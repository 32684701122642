import {
  Layout,
  Config,
  ControlType,
  ControlStatusType,
  RawControlParamMap,
  RawControlParam,
  NormalizedControlMap,
} from './types'

const colorProps = {
  type: 'color',
  min: '000000',
  max: 'FFFFFF',
  step: 5,
  isAnimateRandom: true,
}
export type ControlMap = {
  [key: string]: ControlType
}
export type ControlStatusMap = {
  [key: string]: ControlStatusType
}

export const graphParamGroups = {
  graph: {
    name: 'Graph',
    params: [
      'formula',
      'thetaX',
      'exp',
      'thetaX2',
      'exp2',
      'step',
      'progress',
      'cycles',
      'isConstrainToCycle',
    ],
    extraParams: ['thetaX2', 'exp2'],
  },
  position: {
    name: 'Position',
    params: ['zoom', 'horiz', 'vert', 'rot', 'isFlip'],
  },
  colors: {
    name: 'Color',
    params: [
      'strokeColor',
      'strokeWidth',
      'isVisibleStroke',
      'fillColor',
      'isFillShape',
      'bgColor',
      'isUseBgColor',
    ],
  },
}
export const graphParamGroupOrder = ['graph', 'position', 'colors']

export const currentJsonVersion = '1.0.0'
export const graphParamDefs: ControlMap = {
  formula: {
    type: 'select',
    label: 'Type',
    initialValue: 'flower',
    values: [
      { key: 'flower', value: 'Flower' },
      { key: 'flowerH', value: 'Hyper Flower' },
      { key: 'snowflake', value: 'Snowflake' },
      { key: 'wave', value: 'Wave' },
      { key: 'spiral', value: 'Arch Spiral' },
      { key: 'logspiral', value: 'Log Spiral' },
      { key: 'knot', value: 'Knot' },
      { key: 'knot2', value: 'Knot2' },
    ],
  },
  zoom: {
    type: 'text',
    label: 'Zoom',
    initialValue: 500,
    step: 10,
    min: 1,
    max: 1000,
  },
  thetaX: {
    type: 'text',
    label: 'Petals',
    initialValue: 4,
    step: 1,
    min: 1,
    max: 100,
    isAnimateEnabled: true,
  },
  thetaX2: {
    type: 'text',
    label: 'Petals2',
    initialValue: 4,
    step: 1,
    min: 1,
    max: 100,
  },
  exp: {
    type: 'text',
    label: 'X Factor',
    initialValue: 1,
    step: 1,
    min: -100,
    max: 100,
  },
  exp2: {
    type: 'text',
    label: 'X Factor2',
    initialValue: 1,
    step: 1,
    min: -100,
    max: 100,
  },
  step: {
    type: 'text',
    label: 'Theta Step',
    initialValue: 67,
    step: 1,
    min: 1,
    max: 179,
  },
  progress: {
    type: 'text',
    label: 'Theta Range',
    initialValue: 360,
    step: 1,
    min: 1,
    max: 360,
  },
  cycles: {
    type: 'text',
    label: 'Cycles',
    initialValue: 1,
    step: 1,
    min: 1,
    max: 10,
  },
  isConstrainToCycle: {
    type: 'checkbox',
    label: 'Constrain To Cycle',
    initialValue: false,
  },
  strokeColor: {
    ...colorProps,
    label: 'Stroke',
    initialValue: '#050505',
  },
  fillColor: {
    ...colorProps,
    label: 'Fill',
    initialValue: '#edd012',
  },
  isFillShape: {
    type: 'checkbox',
    label: 'Use Fill',
    initialValue: true,
  },
  bgColor: {
    ...colorProps,
    label: 'Background',
    initialValue: '#dd5f5f',
  },
  isUseBgColor: {
    type: 'checkbox',
    label: 'Use Background',
    initialValue: true,
  },
  isVisibleStroke: {
    type: 'checkbox',
    label: 'Use Stroke',
    initialValue: true,
  },
  strokeWidth: {
    type: 'text',
    label: 'Stroke Width',
    initialValue: 1,
    step: 0.1,
    min: 0,
    max: 2,
  },
  horiz: {
    type: 'text',
    label: 'Horiz',
    initialValue: 0,
    step: 1,
    min: -1500,
    max: 1500,
  },
  vert: {
    type: 'text',
    label: 'Vert',
    initialValue: 0,
    step: 1,
    min: -1500,
    max: 1500,
  },
  rot: {
    type: 'text',
    label: 'Rotate',
    initialValue: 0,
    step: 1,
    min: -180,
    max: 180,
  },
  isFlip: {
    type: 'checkbox',
    label: 'Flip',
    initialValue: false,
  },
}

export const getFrameCount = (normalizedControls: NormalizedControlMap): number => {
  const frameCount = Object.keys(normalizedControls).reduce((acc, key) => {
    const { numStepsInRange } = normalizedControls[key]
    return acc * numStepsInRange
  }, 1)
  return frameCount
}

export const getNormalizedAnimatingControls = (
  graphControls: ControlMap,
  graphConfig: Config,
  initialFrameNumber = 1
): NormalizedControlMap => {
  const normalizedControls: NormalizedControlMap = {}
  Object.keys(graphControls).forEach((key) => {
    // @ts-ignore
    const initialValue = graphConfig[key] as number
    const { step = 0, min = 0, max = 0, type, isAnimateEnabled = false } = graphControls[key]
    if (!isAnimateEnabled || step === 0 || type !== 'text') {
      return
    }
    if (min > max || initialValue < min || initialValue > max) {
      console.log(`can't animate because initialValue is outside range`)
      return
    }
    const minStepsToMax = Math.trunc(((max as number) - initialValue) / step)
    const minStepsToMin = Math.trunc((initialValue - (min as number)) / step)

    if (minStepsToMax < 0 || minStepsToMin < 0) {
      console.log(`can't animate because step doesn't fit range`)
      return
    }
    const pinnedMax = initialValue + step * minStepsToMax
    if (pinnedMax < (max as number)) {
      console.log(` [${key}]: max (${max}) pinned down to ${pinnedMax}`)
    }
    const pinnedMin = initialValue - step * minStepsToMin
    if (pinnedMin > (min as number)) {
      console.log(` [${key}]: min (${max}) pinned up to ${pinnedMin}`)
    }
    const range = pinnedMax - pinnedMin + 1
    const numStepsInRange = step === 1 ? range : Math.trunc(range / step) + 1
    normalizedControls[key] = {
      rangeStart: pinnedMin,
      initialValueSteps: minStepsToMin - (initialFrameNumber - 1), // steps 0-based, frames 1-based
      numStepsInRange,
      step,
      overflow: 'reverse', // 'loop',
    }
  })
  return normalizedControls
}
// const numDecimalPlaces = (num: number) => {
//   let decimalPlaces = 0
//
//   while (num % 1 !== 0) {
//     decimalPlaces++
//     num = Math.floor(num * 10)
//   }
//   return decimalPlaces
// }

const triangleWave = (t: number, period: number, amplitude: number, phase: number) => {
  const timeInPeriod = (t + phase) % period // Calculate the time in the period
  const valueInPeriod = timeInPeriod / period // Calculate the value in the period
  let value = 0

  if (valueInPeriod <= 0.5) {
    value = 2 * amplitude * valueInPeriod
  } else {
    value = 2 * amplitude * (1 - valueInPeriod)
  }

  return value
}
const sawtoothWave = (t: number, period: number, phase: number) => {
  return (phase + t) % period
}
export const calculateFrameDelta = (frameNum: number, controls: NormalizedControlMap) => {
  const configDelta = {}
  Object.keys(controls).forEach((key) => {
    const { step, numStepsInRange, rangeStart, initialValueSteps, overflow } = controls[key]
    const isTriangleWave = overflow === 'reverse'
    if (isTriangleWave) {
      const period = 2 * (numStepsInRange - 1)
      const nextFrameValue =
        rangeStart +
        step * triangleWave(frameNum - 1, period, numStepsInRange - 1, initialValueSteps)
      // @ts-ignore
      configDelta[key] = nextFrameValue
      return
    } else {
      const nextFrameValue =
        rangeStart + step * sawtoothWave(frameNum - 1, numStepsInRange, initialValueSteps)
      // @ts-ignore
      configDelta[key] = nextFrameValue
    }
  })
  return configDelta
}

export const getInitialConfigFromControls = (controls: ControlMap): Config => ({
  step: controls.step.initialValue as number,
  formula: controls.formula.initialValue as Layout,
  isUseBgColor: controls.isUseBgColor.initialValue as boolean,
  zoom: controls.zoom.initialValue as number,
  vert: controls.vert.initialValue as number,
  horiz: controls.horiz.initialValue as number,
  isFlip: controls.isFlip.initialValue as boolean,
  rot: controls.rot.initialValue as number,
  thetaX: controls.thetaX.initialValue as number,
  exp: controls.exp.initialValue as number,
  thetaX2: controls.thetaX2.initialValue as number,
  exp2: controls.exp2.initialValue as number,
  isFillShape: controls.isFillShape.initialValue as boolean,
  isConstrainToCycle: controls.isConstrainToCycle.initialValue as boolean,
  progress: controls.progress.initialValue as number,
  cycles: controls.cycles.initialValue as number,
  strokeColor: controls.strokeColor.initialValue as string,
  fillColor: controls.fillColor.initialValue as string,
  bgColor: controls.bgColor.initialValue as string,
  isVisibleStroke: controls.isVisibleStroke.initialValue as boolean,
  strokeWidth: controls.strokeWidth.initialValue as number,
})

export const getControlsFromStringParams = (params: RawControlParamMap) => {
  const newControls = { ...graphParamDefs }
  Object.keys(params).forEach((key) => {
    if (!(key in graphParamDefs)) {
      return
    }
    const { type: inputType } = graphParamDefs[key]
    const newControl = { ...newControls[key] }
    const rawParam = params[key]
    const hasAnimation = typeof rawParam !== 'string' // TODO: better way?
    let parsedValue: any = rawParam
    if (inputType === 'text') {
      if (hasAnimation) {
        const { step, min, max, value } = rawParam as RawControlParam
        parsedValue = parseFloat(value)
        newControl.step = parseFloat(step)
        newControl.min = parseFloat(min)
        newControl.max = parseFloat(max)
        newControl.isAnimateEnabled = true
      } else {
        parsedValue = parseFloat(rawParam as string)
      }
      newControls[key] = newControl
    } else if (inputType === 'checkbox') {
      parsedValue = rawParam === 'true'
    } else if (inputType === 'color') {
      parsedValue = `#${rawParam}`
    }
    newControl.initialValue = parsedValue
    newControls[key] = newControl
  })
  return newControls
}

export const getControlsFromJsonFile = (params: Object) => {
  const newControls = { ...graphParamDefs }
  Object.keys(params).forEach((key) => {
    if (!(key in graphParamDefs)) {
      return
    }
    const { type: inputType } = graphParamDefs[key]
    const newControl = { ...newControls[key] }
    // @ts-ignore
    let paramValue = params[key]
    const hasAnimation = typeof paramValue === 'object' // TODO: better way?
    if (inputType === 'text') {
      if (hasAnimation) {
        const { step, min, max, value } = paramValue
        paramValue = value
        newControl.step = step
        newControl.min = min
        newControl.max = max
        newControl.isAnimateEnabled = true
      }
      newControls[key] = newControl
    }
    newControl.initialValue = paramValue
    newControls[key] = newControl
  })
  return newControls
}

export const controlsToJson = (controls: ControlMap, config: Config) => {
  const controlsJson = Object.keys(graphParamGroups).reduce(
    (acc, groupName) => {
      // @ts-ignore
      const { params } = graphParamGroups[groupName]
      const groupObject = params.reduce((groupAcc: RawControlParamMap, paramName: string) => {
        // @ts-ignore
        const value = config[paramName]
        const { isAnimateEnabled, min, max, step } = controls[paramName]
        const paramValueOrObject = isAnimateEnabled ? { value, step, min, max } : value
        return { ...groupAcc, [paramName]: paramValueOrObject }
      }, {})
      return { ...acc, [groupName]: groupObject }
    },
    { version: currentJsonVersion }
  )
  return controlsJson
}

export const attachConfigToUrl = (url: URL, config: Config) => {
  url.searchParams.set('version', currentJsonVersion)
  Object.keys(config).forEach((key) => {
    // @ts-ignore
    const value = config[key]
    let paramValue: string = String(value)
    if (key in graphParamDefs) {
      const { type: inputType } = graphParamDefs[key]
      if (inputType === 'checkbox') {
        paramValue = value ? 'true' : 'false'
      } else if (inputType === 'color') {
        paramValue = String(value).slice(1, 7)
      }
    }
    url.searchParams.set(key, paramValue)
  })
}

export const formatFloat = (value: number) => {
  const trimmed = Number.parseFloat(value.toFixed(8))
  return trimmed
}

import cx from 'classnames'
import React, { useContext, useState } from 'react'

import { getFormulaText } from './util'
import { currParamGroups } from './Formulas'
import GraphContext, { GraphContextType } from './GraphContext'
import GraphParam from './GraphParam'
import { ControlMap } from './GraphParamDefs'
import MintSettings from './MintSettings'
import PrintContext, { PrintContextType } from './PrintContext'
import UserContext, { UserContextType } from './UserContext'

interface Props {
  updateControl: (key: string, value: any) => void
  updateConfig: (key: string, param: string, value: any) => void
  controls: ControlMap
}

// @ts-ignore
const { VERSION: version, BUILD_ENV: env = 'alpha' } = MULTIPOLAR_ENV

const VizSettings = ({ controls, updateControl, updateConfig }: Props) => {
  const {
    printContext: { isAnimate },
  } = useContext(PrintContext) as PrintContextType
  const {
    graphContext: { graphConfig: config },
  } = useContext(GraphContext) as GraphContextType
  const {
    userContext: { isFullscreen },
  } = useContext(UserContext) as UserContextType
  const [isShowPrintSettings, setIsShowPrintSettings] = useState(true)
  const paramGroups = currParamGroups(config)
  const defaultGroupExpandedState = new Array(paramGroups.length).fill(true)
  const [groupExpandState, setGroupExpandState] = useState(defaultGroupExpandedState)
  const toggleGroup = (i: number) => {
    setGroupExpandState((prevState) => {
      const newState = [...prevState]
      newState[i] = !prevState[i]
      return newState
    })
  }
  const getSettings = () => {
    return paramGroups.map(({ name, params }, index) => {
      return getSettingsGroup(name, params, index)
    })
  }

  const getSettingsGroup = (name: string, params: string[], index: number) => {
    const isExpanded = groupExpandState[index]
    const isEnabled = paramGroups[index].params.some((key: string) => {
      return controls[key].isAnimateEnabled
    })
    const nameClass = cx('name', { enabled: isEnabled })
    const onGroupClick = () => {
      toggleGroup(index)
    }
    return (
      <div className="group" key={`group-${index}`}>
        <div className={nameClass} onClick={onGroupClick}>
          {name}
        </div>
        {isExpanded && (
          <div className="params">
            {params.map((key: string) => {
              const control = controls[key]
              // @ts-ignore
              const value = config[key]
              return (
                <GraphParam
                  key={`param-${key}`}
                  controlValue={value}
                  controlKey={key}
                  control={control}
                  updateControl={updateControl}
                  updateConfig={updateConfig}
                  isControlled={isAnimate}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }
  const togglePrintSettings = () => {
    setIsShowPrintSettings(!isShowPrintSettings)
  }
  const label = isShowPrintSettings ? getFormulaText(config) : ''

  const helpTextClass = cx('App-small', { fullScreen: isFullscreen })
  const sectionTitleClass = cx('section-title', { fullScreen: isFullscreen })
  return (
    <div className="viz-ui">
      <div className="controlsTitle">Graph Controls</div>
      <div className="viz-settings">{getSettings()}</div>
      {isShowPrintSettings && (
        <div className="controlsHelp">
          <div className={helpTextClass}>
            Use <span>up and down arrow keys</span> within value boxes to increment/decrement
          </div>
          <div className={helpTextClass}>
            Click <span>control labels</span> to show/hide range and animation settings
          </div>
        </div>
      )}
      <div className={sectionTitleClass} onClick={togglePrintSettings}>
        Print/Mint Settings
      </div>
      {isShowPrintSettings && <MintSettings formula={label} />}
      <div className="footer">
        Multipolar v{version} {env}
      </div>
    </div>
  )
}

export default VizSettings

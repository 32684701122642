import React from 'react'
import { ControlMap, controlsToJson } from './GraphParamDefs'
import { Config } from './types'

const DEFAULT_OWNER = 'kevin irlen'

export interface IPrintContext {
  timestamp: number
  copyright: string
  title: string
  pixelRatio: number
  imageSize: { width: number; height: number }
  isShowFormula: boolean
  isShowTitle: boolean
  isShowTimestamp: boolean
  isShowSignature: boolean
  crawlerTextColor: string
  animationMillis: number
  isAnimate: boolean
  animationType: number
  frameNumber: number
}
export const defaultMintConfig: IPrintContext = {
  timestamp: Date.now(),
  copyright: DEFAULT_OWNER,
  title: 'untitled',
  pixelRatio: 1,
  imageSize: { width: 1080, height: 1080 },
  isShowFormula: true,
  isShowTitle: true,
  isShowTimestamp: true,
  isShowSignature: true,
  crawlerTextColor: '#000000',
  animationMillis: 1000,
  isAnimate: false,
  animationType: 0,
  frameNumber: 1,
}

export type PrintContextType = {
  printContext: IPrintContext
  setImageSize: (size: number) => void
  setPixelRatio: (pixelRatio: number) => void
  setTitle: (title: string) => void
  setTimestamp: (size: number) => void
  setIsShowFormula: (isShowFormula: boolean) => void
  setIsShowTitle: (isShowTitle: boolean) => void
  setIsShowTimestamp: (isShowTimestamp: boolean) => void
  setIsShowSignature: (isShowSignature: boolean) => void
  setCrawlerTextColor: (crawlerTextColor: string) => void
  setIsAnimate: (isAnimate: boolean) => void
  setAnimationType: (animationType: number) => void
  setAnimationMillis: (animationMillis: number) => void
  setFrameNumber: (frameNumber: number) => void
}

type PrintContextProviderProps = {
  children?: React.ReactNode
  initialMintConfig: IPrintContext
}

const PrintContext = React.createContext<PrintContextType | null>(null)

export const PrintContextProvider = ({
  children,
  initialMintConfig,
}: PrintContextProviderProps) => {
  // NOTE: only call setPrintContext with (prevContext: IPrintContext) => IPrintContext
  // because you may need to update state while animating????
  const [printContext, setPrintContext] = React.useState<IPrintContext>(initialMintConfig)
  const setPixelRatio = (pixelRatio: number) => {
    setPrintContext((prevContext) => ({ ...prevContext, pixelRatio }))
  }
  const setImageSize = (size: number) => {
    setPrintContext((prevContext) => ({ ...prevContext, imageSize: { width: size, height: size } }))
  }
  const setTimestamp = (timestamp: number) => {
    setPrintContext((prevContext) => ({ ...prevContext, timestamp }))
  }
  const setTitle = (title: string) => {
    setPrintContext((prevContext) => ({ ...prevContext, title }))
  }
  const setIsShowFormula = (isShowFormula: boolean) => {
    setPrintContext((prevContext) => ({ ...prevContext, isShowFormula }))
  }
  const setIsShowTitle = (isShowTitle: boolean) => {
    setPrintContext((prevContext) => ({ ...prevContext, isShowTitle }))
  }
  const setIsShowTimestamp = (isShowTimestamp: boolean) => {
    setPrintContext((prevContext) => ({ ...prevContext, isShowTimestamp }))
  }
  const setIsShowSignature = (isShowSignature: boolean) => {
    setPrintContext((prevContext) => ({ ...prevContext, isShowSignature }))
  }
  const setCrawlerTextColor = (crawlerTextColor: string) => {
    setPrintContext((prevContext) => ({ ...prevContext, crawlerTextColor }))
  }
  const setIsAnimate = (isAnimate: boolean) => {
    setPrintContext((prevContext) => ({ ...prevContext, isAnimate }))
  }
  const setAnimationMillis = (animationMillis: number) => {
    setPrintContext((prevContext) => ({ ...prevContext, animationMillis }))
  }
  const setAnimationType = (animationType: number) => {
    setPrintContext((prevContext) => ({ ...prevContext, animationType }))
  }
  const setFrameNumber = (frameNumber: number) => {
    setPrintContext((prevContext) => ({ ...prevContext, frameNumber }))
  }
  return (
    <PrintContext.Provider
      value={{
        printContext,
        setTimestamp,
        setTitle,
        setPixelRatio,
        setImageSize,
        setIsShowFormula,
        setIsShowTitle,
        setIsShowTimestamp,
        setIsShowSignature,
        setCrawlerTextColor,
        setIsAnimate,
        setAnimationType,
        setAnimationMillis,
        setFrameNumber,
      }}
    >
      {children}
    </PrintContext.Provider>
  )
}

const JSON_SEPARATOR = '  '
export const getManifest = (
  printContext: IPrintContext,
  graphControls: ControlMap,
  config: Config
) => {
  const controlsJson = controlsToJson(graphControls, config)
  const mintHistory = Object.keys(printContext).reduce((acc, key) => {
    if (key === 'isAnimate') {
      return acc
    }
    // @ts-ignore
    const value = printContext[key]
    const valueForJson = key === 'timestamp' ? new Date(value).toISOString() : value
    return { ...acc, [key]: valueForJson }
  }, {})
  const manifest = { ...controlsJson, mintHistory }
  return JSON.stringify(manifest, null, JSON_SEPARATOR)
}

export default PrintContext

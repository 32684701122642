import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga'

import './index.scss'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { UserContextProvider } from './UserContext'

// import ReactGA4 from 'react-ga';
// const GA_ID = 'G-0Z7CPRJ0YE'; // TODO: GA4 seems not to work

const GA_ID = 'UA-240769720-1'
ReactGA.initialize(GA_ID)

// @ts-ignore
const { AUTH_KEY, AUTH_TOKEN } = MULTIPOLAR_ENV
const localAuthorKey = localStorage.getItem(AUTH_KEY)

const isReadOnly = !localAuthorKey || !AUTH_TOKEN || localAuthorKey !== AUTH_TOKEN
const initialUserConfig = {
  isReadOnly,
  isAdvanced: !isReadOnly,
  isAutoResize: isReadOnly,
  isFullscreen: false,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <UserContextProvider initialUserConfig={initialUserConfig}>
      <App />
    </UserContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
